import React from "react";

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
      <h1 className="text-4xl font-bold mb-8">Oops! Page Not Found</h1>
      <p className="text-lg mb-6">
        The page you are looking for doesn't exist. It may have been moved or
        deleted.
      </p>
      <p className="text-6xl mb-8">😞</p>
      <p>
        <a href="/" className="text-white underline">
          Go to Homepage
        </a>
      </p>
    </div>
  );
};

export default Error;
