import React, { useState, useRef } from "react";
import { ABOUTMETEXT } from "../variables";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsGithub,
} from "react-icons/bs";

const About = () => {
  const textStyle = "text-white sm:text-[1.3rem] text-justify font-mono";
  const handleRedirectTo = (urlString) => {
    // window.location.href = urlString;
    window.open(urlString, "_blank");
  };

  return (
    <div className="about select-none w-full h-full bg-black relative">
      <div className="h-auto text-center text-pink-700 font-bold text-4xl p-1">
        README.md
      </div>

      <div
        className=" w-full h-auto sm:h-full flex flex-col md:flex-row items-center justify-around md:justify-center gap-[2em] "
        id="About"
      >
        <div className="block md:hidden lg:block absolute top-[10%] right-[10%]">
          <img src="./images/exclamation.svg" alt="sorry!" />
        </div>
        <div className="image w-[50%] sm:w-[50%] sm:h-[400px] md:w-[50%] lg:w-[25%] p-[0.2em] sm:p-[0.15em] bg-white  mt-2">
          <img
            className="w-full h-full object-cover rounded-2xl inline-block"
            src="./images/img.jpg"
            alt="sorry!"
          />
        </div>
        <div className="aboutText p-3 w-[90%] md:w-[60%] flex flex-col space-y-8">
          <p className={textStyle}>{ABOUTMETEXT}</p>
          <div className="border border-chocolate text-white p-2 w-fit hover:cursor-pointer hover:scale-105 hover:text-chocolate hover:font-bold transition-all duration-700">
            <a href="documents/CV_SACHIN.pdf" download>
              View My CV
            </a>
          </div>
        </div>
        <div className="hidden w-full md:absolute top-[80%] md:flex justify-center p-1 gap-6">
          <BsFacebook
            size={32}
            className="hover:scale-105 cursor-pointer text-chocolate"
            onClick={() =>
              handleRedirectTo("https://www.facebook.com/sachinBhusal22/")
            }
          />
          <BsInstagram
            size={32}
            className="hover:scale-105 cursor-pointer text-chocolate"
            onClick={() =>
              handleRedirectTo("https://www.instagram.com/_sachinsta__/")
            }
          />
          <BsLinkedin
            size={32}
            className="hover:scale-105 cursor-pointer text-chocolate"
            onClick={() =>
              handleRedirectTo(
                "https://www.linkedin.com/in/sachin-bhusal-42b548198/"
              )
            }
          />
          <BsTwitter
            size={32}
            className="hover:scale-105 cursor-pointer text-chocolate"
            onClick={() => handleRedirectTo("https://twitter.com/ssachinn_")}
          />
          <BsGithub
            size={32}
            className="hover:scale-105 cursor-pointer text-chocolate"
            onClick={() => handleRedirectTo("https://github.com/bhusalSachin")}
          />
        </div>

        <div className="hidden md:block absolute top-[80%] right-[10%]">
          <img src="./images/fun_arrow.svg" alt="sorry!" />
        </div>
      </div>
    </div>
  );
};

export default About;
