import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { PROJECT_CATALOGUE, RAINBOW_COLORS } from "../variables";

const Timeline = () => {
  return (
    <VerticalTimeline lineColor="#D4AF4A">
      {PROJECT_CATALOGUE.map((project, index) => {
        return (
          <VerticalTimelineElement
            key={project.title + index}
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "#4b4949",
              color: "#fff",
            }}
            date={project.date}
            iconStyle={{
              background: RAINBOW_COLORS[index % RAINBOW_COLORS.length],
            }}
          >
            <h3 className="vertical-timeline-element-title text-xl font-bold text-chocolate">
              {project.title}
            </h3>
            <p>{project.description}</p>
            {project.github !== null && (
              <div className="flex flex-col mt-4">
                <span className="underline underline-offset-4">
                  View Source Code:
                </span>
                <div className="flex flex-col my-2">
                  {project.github.map((link, idx) => {
                    return (
                      <a
                        key={link + idx}
                        href={link}
                        target="_blank"
                        className="hover:text-pink-400"
                      >
                        {link}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
            {project.siteurl && (
              <div
                key={project.siteurl + index}
                className="flex flex-col space-y-2 mt-4 "
              >
                <span className="underline underline-offset-4">
                  Visit Site:
                </span>
                <a
                  href={project.siteurl}
                  target="_blank"
                  className="hover:text-pink-400"
                >
                  {project.siteurl}
                </a>
              </div>
            )}
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default Timeline;
