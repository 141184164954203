import React from "react";
import Timeline from "./Timeline";

const Portfolio = () => {
  return (
    <div className="portfolio w-full h-auto bg-black p-[1em] relative">
      <div className="h-auto text-center text-pink-700 text-4xl p-1 m-4 font-bold">
        Project/Work History 📜
      </div>
      <div className="mt-24 h-[65em] overflow-auto scrollbar-thin scrollbar-thumb-pink-500 scrollbar-track-pink-300">
        <Timeline />
      </div>
    </div>
  );
};

export default Portfolio;
