export const ABOUTMETEXT =
  "As a driven and talented web developer, I, Sachin Bhusal, am always on the lookout for new ways to innovate and make a positive impact in the tech world. With a passion for all things digital and a natural talent for problem-solving, I am constantly seeking out new challenges and opportunities to hone my skills and make a mark in the industry. Currently an undergraduate student at Pulchowk Campus in Lalitpur, Nepal, studying Electronics, Communication, and Information Engineering, I bring a unique blend of technical expertise and creative flair to every project I work on.";

export const PROJECT_CATALOGUE = [
  {
    title: "merosalary.com ",
    description: `As the Lead Developer at Merosalary.com, I played a pivotal
role in the development and deployment of the platform, which
utilizes Next.js and Firebase Firestore database technology.
Collaborating closely with the founding team, I contributed
to the platform’s architecture, design, and feature implemen-
tation. My responsibilities included overseeing the technical
aspects of the project, leading a team of developers, and ensur-
ing the successful integration of Next.js and Firebase Firestore
to create a seamless user experience. Throughout the project
lifecycle, I provided technical expertise, problem-solving, and
guidance to ensure project objectives were met efficiently and
effectively. Under my leadership, Merosalary.com emerged as a
sophisticated platform, offering users intuitive salary manage-
ment solutions powered by cutting-edge technologies.`,
    date: "February, 2024 - present",
    github: null,
    siteurl: "https://merosalary.com/",
  },
  {
    title: "CryptoJune ",
    description: `In my role as a Lead Developer at Cryptojune, I led the develop-
ment and maintenance of a multifaceted blogging platform and
web analytics tool leveraging cutting-edge technologies. Utiliz-
ing Next.js for the frontend and WordPress as a headless CMS,
I orchestrated the seamless integration of content management
and dynamic web functionality. Additionally, I implemented
Node.js/Express.js backend services coupled with MongoDB for
robust data management and storage. Collaborating closely
with cross-functional teams, I contributed to the architectural
design, feature implementation, and deployment phases, ensur-
ing optimal performance and scalability. Through agile devel-
opment methodologies and continuous integration practices, I
played a pivotal role in positioning Cryptojune as a premier
destination for crypto enthusiasts, providing comprehensive in-
sights and engaging content within the evolving landscape of
Web3 analytics and cryptocurrency distribution mechanisms.`,
    date: "December, 2023 - February, 2024",
    github: null,
    siteurl: "https://cryptojune.com/",
  },
  {
    title: "Dolpha ",
    description: `As a Frontend Developer at Dolpha, I played a pivotal role in
the creation of a sophisticated Polkadot analytics website tai-
lored for power users. Specializing in React.js, I led the frontend
development efforts, contributing to the design and implemen-
tation of intuitive user interfaces and data visualization fea-
tures. Working closely with the development team, I ensured
seamless integration of frontend components with backend sys-
tems, resulting in a dynamic and user-friendly platform that
delivers comprehensive insights into the Polkadot ecosystem.`,
    date: "October, 2023 - December, 2023",
    github: null,
    siteurl: "https://dolpha.com/",
  },
  {
    title: "Healthline (Backend only)",
    description: `In our captivating minor project, we embarked on a remarkable journey to create an exceptional app using the power of Flutter. With an unwavering focus on hospital integration, we ingeniously connected multiple hospitals within a single app, revolutionizing the way healthcare facilities are accessed. Our groundbreaking solution effortlessly showcased the availability of various medical services and facilities across these hospitals, ensuring users are informed and empowered to make informed decisions about their healthcare needs. Leveraging my expertise, I meticulously developed a robust backend server using the dynamic duo of Node.js and Express.js, seamlessly integrating with the versatile MongoDB database. Through this endeavor, I not only acquired valuable hands-on experience in backend development but also deepened my understanding of healthcare systems and the vital role technology plays in shaping a better future for patient care.`,
    date: "December, 2022 - March, 2023",
    github: ["https://github.com/bhusalSachin/healthline-server"],
    siteurl: null,
  },
  {
    title: "Aakhyaan Academy",
    description: `During my involvement with Aakhyaan Academy, a dynamic startup revolutionizing the online learning landscape for engineering and medical entrance aspirants, I contributed as a dedicated MERN stack developer, actively participating in the creation of their groundbreaking online platform. This immersive experience provided me with the opportunity to acquire and apply an extensive range of skills, including the development and integration of various modules within the MERN stack framework. From architecting intuitive user interfaces to implementing seamless data management systems, I played an instrumental role in shaping the platform's functionality and user experience. Collaborating with a talented team, I embraced agile methodologies and fostered an environment of continuous improvement, enabling us to deliver a highly interactive and personalized learning environment for students. This exceptional project not only expanded my technical acumen but also instilled in me a profound appreciation for the transformative impact of technology in empowering learners worldwide.`,
    date: "April, 2022 - August, 2022",
    github: null,
    siteurl: "https://aakhyaan.org/",
  },
  {
    title: "Kuruwaa - Mobile Application (React Native)",
    description: `In the bustling atmosphere of the renowned hack-a-week, held at Pulchowk Campus, where knowledge and innovation thrive, our team embarked on a transformative journey, birthing the extraordinary 'Kuruwaa' app. Aptly named after the Nepali term for "one who waits," Kuruwaa emerged as a beacon of hope for patients and the elderly, who often find themselves confined to their beds, yearning for constant care and support.Understanding the challenges faced by their families, we harnessed the power of technology to bridge the gap.Through our expertise in React Native, we crafted an intuitive mobile application, complemented by a robust backend built with Node.js / Express.js, and MongoDB as our reliable database.This exceptional undertaking taught us invaluable lessons in empathy, problem- solving, and teamwork, enabling us to develop a profound understanding of the needs of the vulnerable.Kuruwaa stands as a testament to our dedication to improving lives through innovative solutions, and we take immense pride in empowering families and bringing solace to those in need.`,
    date: "February, 2022",
    github: [
      "https://github.com/bhusalSachin/kuruwaa",
      "https://github.com/bhusalSachin/kuruwaserver",
    ],
    siteurl: null,
  },
  {
    title: "TypingTest Web Application",
    description:
      "Introducing 'TypingTest,' a captivating real-time web application that takes the thrill of typing competitions to a whole new level. Built with React.js for a sleek and intuitive front-end experience, this project combines the power of Node.js and Express.js on the backend, with MongoDB as the robust database solution. Leveraging the incredible capabilities of Socket.io, TypingTest provides seamless real-time connections, allowing multiple users to compete head-to-head in exhilarating typing challenges. Throughout the development of this remarkable application, I delved into the fascinating world of web sockets, gaining valuable insights and expanding my knowledge of real-time communication. Join the excitement of TypingTest and witness firsthand the convergence of cutting-edge technologies, inspiring competition, and the joy of typing mastery.",
    date: "January, 2022",
    github: [
      "https://github.com/bhusalSachin/typingtest",
      "https://github.com/bhusalSachin/typingtestserver",
    ],
    siteurl: null,
  },
  {
    title: "Twitter Bot",
    description:
      "Leveraging the twit@2.2.11 npm package, I crafted an exceptional showcase of a Twitter Bot to delve into realm of searching for specific topic posts, posting tweets effortlessly, and even retrieving a list of followers.",
    date: "November, 2021",
    github: ["https://github.com/bhusalSachin/twitterBot"],
    siteurl: null,
  },
  {
    title: "Netflix Landing Page (UI) using React js",
    description:
      "In my exhilarating journey of mastering ReactJS, I embarked on a remarkable project that allowed me to unleash my creativity and gain invaluable skills. With an insatiable thirst for knowledge, I undertook the ambitious task of reimagining the renowned Netflix landing page. Through this captivating endeavor, I not only honed my design prowess, but also delved deep into the fundamentals of user experience and interface design. Guided by a relentless pursuit of excellence, I meticulously crafted an aesthetically pleasing and intuitive interface, seamlessly integrating ReactJS components to deliver a dynamic user experience. To showcase my newfound expertise, I proudly deployed the project using Firebase hosting, solidifying my understanding of cloud-based deployment strategies. This enthralling journey has been a testament to my unwavering dedication to growth, as I eagerly absorbed the intricacies of design principles and acquired the technical know-how to deploy web applications.",
    date: "April, 2021",
    github: ["https://github.com/bhusalSachin/netflix-clone"],
    siteurl: "https://netflix-clone-60b0e.web.app/",
  },
  {
    title: "Amazon.com UI Clone using React js and Firebase",
    description:
      "In my transformative journey of mastering ReactJS, I embarked on an awe-inspiring project that allowed me to push the boundaries of my skills and delve into the realm of e-commerce. With an unwavering determination to learn and grow, I undertook the challenge of creating a captivating online store from scratch. Through countless hours of meticulous coding and design, I brought to life a stunning and seamless shopping experience, employing ReactJS components to build a highly interactive and responsive interface. With each line of code, I deepened my understanding of state management, routing, and data integration, ensuring a flawless user journey.",
    date: "October, 2020",
    github: ["https://github.com/bhusalSachin/amazon-clone-with-react"],
    siteurl: "https://challenge-62332.web.app/",
  },
];

export const RAINBOW_COLORS = [
  "#00b1af",
  "#f00000",
  "#a000c0",
  "#ff8000",
  "#4040ff",
  "#ffff00",
  "#007940",
];
