import React, { useState } from "react";
// import "./Intro.css";
import Cube from "./Cube";
import { BsArrowDownCircle } from "react-icons/bs";

const Intro = () => {
  const [changableText, setChangableText] = useState("Sachin Bhusal.");

  const introTextStyle =
    "text-white text-[3rem] sm:text-[5rem] leading-none w-full sm:w-fit animate-opacityAnimation select-none hover:text-chocolate";

  return (
    <div
      className="flex p-2 sm:p-0 items-center sm:justify-center h-full w-full relative"
      id="Intro"
    >
      <div className="p-4 w-full sm:w-[50%]">
        <div className={`${introTextStyle}`}>Hi 👋</div>
        <div className={`${introTextStyle}`}>I am</div>
        <div
          className={`${introTextStyle} sm:text-[6rem] text-chocolate relative leading-[1.5] after:content['*'] after:h-full after:bg-black after:absolute after:top-0 after:right-0 after:animate-myNameWidth after:z-10 before:content['*'] before:w-[0.05em] before:h-[90%] before:bg-white before:absolute before:top-0 before:z-10 before:animate-cursor`}
          onMouseEnter={() => setChangableText("Web developer.")}
          onMouseLeave={() => setChangableText("Sachin Bhusal.")}
        >
          {changableText}
        </div>
      </div>
      <div className="animate-bounce w-[40%] h-full hidden md:flex items-center justify-end relative perspective-9 origin-top-left ">
        <Cube />
      </div>
      <div className="cursor-pointer absolute bottom-12 right-12 animate-bounce hover:animate-ping">
        <BsArrowDownCircle fontSize="3rem" color="#D4AF4A" />
      </div>
    </div>
  );
};

export default Intro;
