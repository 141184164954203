import React from "react";
import "./Cube.css";
import { AiFillHtml5, AiFillGithub, AiFillApi } from "react-icons/ai";
import {
  SiTailwindcss,
  SiExpress,
  SiReact,
  SiMongodb,
  SiJavascript,
  SiFirebase,
} from "react-icons/si";
import { IoLogoCss3, IoGitMerge } from "react-icons/io5";
import { TbBrandReactNative } from "react-icons/tb";
import { FaNode } from "react-icons/fa";
import { BsServer } from "react-icons/bs";

const Cube = () => {
  return (
    <div className="container rounded-full">
      <div className="side front">
        <AiFillHtml5 size={64} color="white" />
        <FaNode size={64} color="white" />
        <IoLogoCss3 size={64} color="white" />
      </div>
      <div className="side back">
        <SiExpress size={64} color="white" />
        <BsServer size={64} color="white" />

        <SiFirebase size={64} color="white" />
      </div>
      <div className="side right">
        <AiFillGithub size={64} color="white" />
        <AiFillApi size={64} color="white" />
        <SiTailwindcss size={64} color="white" />
      </div>
      <div className="side left">
        <SiMongodb size={64} color="white" />
        <TbBrandReactNative size={64} color="white" />
      </div>
      <div className="side up">
        <SiReact size={64} color="white" />
      </div>
      <div className="side down">
        <SiJavascript size={64} color="white" />
        <IoGitMerge size={64} color="white" />
      </div>
    </div>
  );
};

export default Cube;
