import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import Message from "./Message";

const ContactMe = () => {
  const [mailForm, setMailForm] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const [showMessage, setShowMessage] = useState({
    isShow: false,
    status: false,
    message: "",
  });
  const handleSendEmail = (e) => {
    e.preventDefault();

    try {
      emailjs
        .sendForm(
          "service_knae2i9",
          "template_708eb8v",
          e.target,
          "N5HfePekf6k1dQ7L9"
        )
        .then(
          (result) => {
            // console.log(result.text);
            setMailForm({ email: "", subject: "", message: "" });
            setShowMessage({
              isShow: true,
              status: true,
              message:
                "Hello, I got the email and will reach out to you soon. Thank you!",
            });
          },
          (error) => {
            // console.log(error.text);
            setShowMessage({
              isShow: true,
              status: false,
              message:
                error.text || "Oops! Couldn't send the email. Please retry!",
            });
          }
        );
    } catch (error) {
      // console.log(error);
      setShowMessage({
        isShow: true,
        status: false,
        message: error || "Oops! Couldn't send the email. Please retry!",
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage((prev) => {
        return { ...prev, isShow: false };
      });
    }, 6000);

    return () => clearTimeout(timer);
  }, [showMessage]);

  return (
    <div className="w-full h-full bg-black flex flex-col items-center justify-center">
      <h2 className="text-pink-600 text-3xl md:text-4xl font-bold mt-16">
        Contact Me 👀
      </h2>
      <form
        onSubmit={(e) => handleSendEmail(e)}
        className="mt-16 p-4 w-[90%] md:w-[60%] lg:w-[50%] xl:w-[40%] 2xl:w-[30%]"
      >
        <div>
          <label id="email" className="text-chocolate p-1 text-md md:text-lg">
            Your email address
          </label>
          <input
            id="email"
            className="w-full border-none outline-none transition-all rounded-md p-2 md:text-lg"
            placeholder="example@gmail.com"
            name="email"
            type="email"
            value={mailForm.email}
            required
            onChange={(e) =>
              setMailForm((prev) => {
                return { ...prev, email: e.target.value };
              })
            }
          />
        </div>
        <div className="mt-4">
          <label id="subject" className="text-chocolate p-1 text-md md:text-lg">
            Subject
          </label>
          <input
            id="subject"
            className="w-full border-none outline-none transition-all rounded-md p-2 md:text-lg"
            placeholder="About Collaboration"
            type="text"
            name="subject"
            value={mailForm.subject}
            required
            onChange={(e) =>
              setMailForm((prev) => {
                return { ...prev, subject: e.target.value };
              })
            }
          />
        </div>
        <div className="mt-4">
          <label
            id="message"
            className="text-chocolate p-1 text-md md:text-lg "
          >
            Type your message here
          </label>
          <textarea
            id="message"
            className="w-full h-[20em] rounded-md p-2 outline-none border-none resize-none"
            type="text"
            name="message"
            value={mailForm.message}
            onChange={(e) =>
              setMailForm((prev) => {
                return { ...prev, message: e.target.value };
              })
            }
          />
        </div>

        <button
          type="submit"
          className="outline outline-chocolate border border-white p-2 mt-4 rounded-md hover:bg-chocolate text-white transition-all duration-700"
        >
          Send Email
        </button>
      </form>
      {showMessage.isShow && (
        <Message message={showMessage.message} status={showMessage.status} />
      )}
    </div>
  );
};

export default ContactMe;
