import React from "react";
import Intro from "./Intro";
import About from "./About";
import Portfolio from "./Portfolio";
import ContactMe from "./ContactMe";

const Client = () => {
  return (
    <div className="App bg-black h-screen w-full overflow-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-300">
      <Intro />
      <About />
      <Portfolio />
      <ContactMe />
    </div>
  );
};

export default Client;
