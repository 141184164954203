import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes } from "react-router-dom";
// import Index from "./components/Admin/Index";
import Client from "./components/Client";
import { Route } from "react-router-dom";
import Error from "./components/Error";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Client />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
